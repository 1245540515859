
import { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from '@ionic/vue-router';
//import Authentication from '../views/Authentication.vue'


import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "../main";




const getEventPath = () => {

const q = query(collection(db, "events"));
const unsubscribe = onSnapshot(q, (querySnapshot) => {

  querySnapshot.forEach((doc) => {
    const thePlaceID = doc.data().placeID
    return  "&" + thePlaceID + "&" + doc.id
  });
  
});

}

// const getCityPath = () => {

//   const unsubscribe = onSnapshot(q, (querySnapshot) => {

//     querySnapshot.forEach((doc) => {
//       const thePlaceID = doc.data().city
//       return  "&" + thePlaceID + "&" + doc.id
//     });
    
//   });


// }



const routes: Array<RouteRecordRaw> = [

  {
    path: '/addPlace',
    component: () => import('@/views/AddPlace.vue')
  },
  {
    path: '/createEvent',
    component: () => import('@/views/CreateEvent.vue')
  },
  {
    path: '/event/:getEventPath()',
    component: () => import('@/views/EventDetail.vue')
  },
  {
    path: '/auth',
    component: () => import('@/views/Authentication.vue')
  },

  {
    path: '/mailinglist',
    component: () => import('@/views/MailingList.vue')
  },

  {
    path: '/cities',
    component: () => import('@/views/Cities.vue')
  },

  {
    path: '/checks',
    component: () => import('@/views/Checks.vue')
  },

  {
    path: '/',
    component: () => import('@/views/Cities2.vue')
  },
  
  {
    path: '/events',
    component: () => import('@/views/Events.vue')
  },
  {
    path: '/map',
    component: () => import('@/views/Map.vue')
  },
  {
    path: '/checkoutsuccess',
    component: () => import('@/views/CheckoutSuccess.vue')
  },
  {
    path: '/checkoutcancel',
    component: () => import('@/views/CheckoutCancel.vue')
  },


  {
    path: '/eventdetail',
    component: () => import('@/views/EventDetail.vue')
  },

  {
    path: '/privacypolicy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },

  {
    path: '/terms&conditions',
    component: () => import('@/views/Terms&Conditions.vue')
  },

  {
    path: '/refundandcancellationpolicy',
    component: () => import('@/views/RefundAndCancellationPolicy.vue')
  },

  {
    path: '/cookiepolicy',
    component: () => import('@/views/CookiePolicy.vue')
  },

  {
    path: '/disclaimer',
    component: () => import('@/views/Disclaimer.vue')
  },

  {
    path: '/accessibilitystatement',
    component: () => import('@/views/AccessibilityStatement.vue')
  },

  {
    path: '/support',
    component: () => import('@/views/Support.vue')
  }

  



  

  





]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
