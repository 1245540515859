import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/main.css'

// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// import { getAuth } from "firebase/auth";
// "indexedDBLocalPersistence" used instead of "getAuth" in order to make "onAuthStateChanged" 
// work on mobile (getAuth assumes browser context)
// info here: 
// https://firebase.google.com/docs/auth/web/custom-dependencies
// https://github.com/firebase/firebase-js-sdk/issues/5019
import {initializeAuth, indexedDBLocalPersistence} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';
import { onAuthStateChanged } from "firebase/auth";
import { ref } from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
// import { useRouter } from "vue-router";
// export const testRouter = useRouter()


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC95ROmvP85p_nDM8kW7BzWRYQnka5qx8I",
  authDomain: "dinners-a6083.firebaseapp.com",
  projectId: "dinners-a6083",
  storageBucket: "dinners-a6083.appspot.com",
  messagingSenderId: "61862713804",
  appId: "1:61862713804:web:6e070245976c4d6c042569"
};




// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const auth = initializeAuth(firebaseApp, {
  persistence: indexedDBLocalPersistence,
  // No popupRedirectResolver defined
});
export const functions = getFunctions(firebaseApp);
export const user = auth.currentUser;
export const userSignedIn = ref(false)
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBuv9znfcgfXXtPU8pGjw5E6IWrf0F_WhY'

// firebase stripe extension 
// https://github.com/stripe/stripe-firebase-extensions/blob/next/firestore-stripe-web-sdk/README.md

import { getApp } from "@firebase/app";
import { getStripePayments } from "@stripe/firestore-stripe-payments";
const stripe_app = getApp(); // 'stripe_app' is 'app' in documentation, but app has already been declared, so changed it to 'stripe_app'

export const payments = getStripePayments(stripe_app, {
					productsCollection: "products",
					customersCollection: "users",
					});


import { getPath } from "@/composables/useGetPath";


onAuthStateChanged(auth, (user) => {

  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // const uid = user.uid;
    userSignedIn.value = true;
   
    

    return  userSignedIn.value 
    // ...
  } else {
    // User is signed out
    // ...
    userSignedIn.value = false;
   

    return  userSignedIn.value 
  }
});



const app = createApp(App)
  .use(IonicVue)
  .use(router);

  // eslint-disable-next-line
app.component('Datepicker', Datepicker);

// treat all tags starting with 'ion-' as custom elements
// app.config.compilerOptions.isCustomElement = (tag) => {
//   return tag.startsWith('capacitor-')
// }



router.isReady().then(() => {
  app.mount('#app');
});